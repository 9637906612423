@import "reset";
@import "base";
@import "setting";
#no-header{
  .nav-class{
        position: relative !important;
  }
}
.vjm-language{
      display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 47px;
    height: auto;
    justify-content: flex-start;
    padding-top: 0;
    border-radius: 6px;
    position: relative;
    right: 0;
    z-index: 111;
  img{
    position: absolute;
     right: 1px;
    width: 9px;
    top: 52%;
    transform: translate(0, -50%) rotate(-90deg);
    @media (max-width: 1500px) {
      right: -3px;
      width: 11px;
      top: 54%;
    }
  }
  &:hover{
    .lng{
      opacity: 1;
    }
  }
/*  @media (max-width: 1200px) {
    right: -39px;
  }
  @media (max-width: 992px){
    position: absolute;
    right: 63px;
  }*/

  &:before{
    //c
    position: absolute;
    right: -17px;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 11px;
    width: 10px;
    @media (max-width: 1700px) {
      right: -12px;
    }
    @media (max-width: 1500px) {
      right: -9px;
    }
    @media (max-width: 992px) {
      display: none;
    }
  }

  .lng{
    position: absolute;
    top: 35px;
    opacity: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
     background: #cede2f;
    padding: 10px 0;
    right: -9px;
    border-radius: 5px;
    transition: 200ms linear all;
    a{
       color: #000;
      margin: 3px 0;
      font-size: 15px;
      @media (max-width: 1500px) {
        width: auto !important;
      }
    }
  }
  .current_lng{
    position: relative;
    width: 100%;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
        font-size: 16px;
    font-weight: bold;
    @media (max-width: 1500px) {
 font-size: 13px;
    }
    img{
      position: absolute;
      width: 31px;
      right: 41px;
      top: 0px;
    }
  }
}
.title-bar{
      display: flex;
    align-items: flex-start;
    margin: 80px 0;
    margin-bottom: 7px;
        @media (max-width: 1500px) { 
          margin: 32px 0;
    } 
    span{
      width: 141px;
    height: 5px;
    background: #CEDE2F;
    margin-right: 250px;
    margin-top: 34px;
     @media (max-width: 1500px){ 
    height: 3px; 
    margin-right: 95px;  
          margin-top: 28px;
      }
          @media (max-width: 992px){
        height: 2px; 
           width: 61px;
    margin-right: 25px;
        margin-top: 12px;
    }
    
     @media (max-width: 450px){
       display:none; 
    }
    }
    p{
          font-size: 60px;
    color: #000; 
    font-weight: bold;
    width: 1000px;
    line-height: 60px;
         @media (max-width: 1500px){
      width: 700px;
    line-height: 46px;
        font-size: 38px;
    }
     @media (max-width: 992px){
      width: 300px;
    line-height: 28px;
    font-size: 22px;
    }
    }
}
.nav-class{
      position: fixed;
    width: 100%;
    background: white;
    z-index: 11111;
    border-bottom: 3px solid black;
    padding: 10px 0;
}
.active-li{
  position: relative;
  transition: 300ms linear all;
      @media (max-width: 992px){
        color: #cede2f !important;
      }
  &:before{
    content: '';
    width: 100%;
    height: 3px;
    background: #cede2f;
    transition: 300ms linear all;
    position: absolute;
    bottom: -10px;
    @media (max-width: 992px){
          display: none;
    }
  }
}
.social-link {
  display: flex;
  align-items: center; 
  margin-left: 100px;
  position: relative;
    @media (max-width: 1700px) {
   margin-left: 50px;
  }
    @media (max-width: 1169px) {
   display:none;
  }
  &:before {
    content: '';
    height: 116%;
    width: 2px;
    background: #9f9f9f54;
    position: absolute;    
    left: -49px;
    top: 50%;
    transform: translate(0, -50%);
     @media (max-width: 1700px){
          left: -27px;
    }
  }
  a {
    width: 35px;
    height: 35px;
    display: inline-flex;
    background: #9F9F9F;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    transition: 300ms linear all;
     @media (max-width: 1700px) {
      margin-right: 21px;
  }
     @media (max-width: 1500px){
          margin-right: 17px;
    }
    @media (max-width: 1050px) {
   width: 26px;
    height: 26px;
  }
    &:hover{
      background: #cede2f;
      opacity: 1;
      i{
        color: #9F9F9F;
      }
    }
    &:last-of-type {
      margin-right: 0px;
    }
    i {
      color: #fff;
      transition: 300ms linear all;
          @media (max-width: 1500px) {
            font-size: 13px;
      }
      @media (max-width: 1050px) {
          font-size: 10px; 
  }
    }
  }
}

nav, footer {
  .content-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo{
       @media (max-width: 1500px) {
       width: 100px;
       img{
            width: 100%;
       }
  }
    }
    a {
      font-size: 19px;
      color: #000;
        @media (max-width: 1600px){
            font-size: 17px;
      }
  @media (max-width: 1500px) {
    font-size: 14px;
  }
    @media (max-width: 1220px){
           font-size: 13px;
  }
  
    }
    .info {
      display: flex;
          margin-left: 50px;
           @media (max-width: 1500px){
            margin-left: 14px;
          }
     /*  @media (max-width: 650px){
           display: none; 
  }*/
      .link {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        a {
          font-weight: bold;
          span {
           color: #000000;
    margin-right: 5px;
    font-size: 35px;
    line-height: 44px;
       @media (max-width: 1500px) {
          font-size: 22px;
    line-height: 27px;
    }
    @media (max-width: 400px) {
          font-size: 19px;
    line-height: 23px;
    }
          }
        }
      } 
    }
    .menu {
          margin-left: auto;
     @media (max-width: 992px) {
        position: fixed;
    background: white;
    z-index: 1111;
    top: 0;
    bottom: 0;
    height: 100vh;
    right: -50%;
    flex-direction: column;
    width: 50%;
    align-items: center;
    padding-top: 100px;
    border-left: 1px solid black;
    transition: 500ms linear all
  }
   @media (max-width: 700px) {
    width: 75%;
      right: -75%;
   }
   @media (max-width: 600px) {
    width: 80%;
      right: -80%;
   }
   @media (max-width: 400px) {
    width: 85%;
      right: -85%;
   }
      li {
        margin-right: 27px;
        position: relative;
         @media (max-width: 1755px) {
         margin-right: 15px;
      }
 @media (max-width: 1150px){ 
    margin-right: 10px;
  }
     @media (max-width: 992px) {
          margin-right: 0px;
     }
 
        &:before{ 
          content: '';
          width: 100%;
          height: 3px;
          background: #cede2f;
          position: absolute;
          bottom: -10px;
          transition: 300ms linear all;
          opacity: 0;
             @media (max-width: 992px) {
         display: none;
     }
        }
        &:hover{
          &:before{
opacity: 1;
          }
        }
        &:last-of-type {
          margin-right: 0px;
        }
        a{
           @media (max-width: 1200px){
             color: #000;
  }
    @media (max-width: 700px) {
    font-size: 21px;
    margin-bottom: 14px;
    display: inline-flex;
  }
        }
      }
    }
  }
}


nav {
  padding-top: 40px;
  padding-bottom: 40px;
   @media (max-width: 992px){
              padding-top: 20px;
  padding-bottom: 20px;
  }
}

p, ul {
  margin-bottom: 0 !important;
}

ul {
  display: flex !important;
}

.title {
  width: 100%;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 70px;
  display: inline-flex;
    @media (max-width: 1500px) {
    font-size: 25px;
    margin-bottom: 25px;
    }
          @media (max-width: 992px) {
             font-size: 19px;
        }
}

//style
.slider {
  .owl-nav{
    display: none;
  }
  .owl-dots {
    display: block !important;
    position: absolute;
    bottom: 83px;
    right: 90px;
      @media (max-width: 992px)  {
          bottom: 35px;
    right: 53px;
    }
     @media (max-width: 600px)  {
          bottom: 5px;
    right: 15px;
    }
    button {
      width: 67px;
      height: 5px;
      background: white !important;
      margin-right: 37px;
       @media (max-width: 992px)  {
              width: 27px;
                 margin-right: 17px;
    }
     @media (max-width: 600px)  {
         margin-right: 7px;
          height: 1px;
    }
      &:last-of-type {
        margin-right: 0px;
      }
    }
    .active {
      background: #CEDE2F !important;
    }
  }
  .item {
    position: relative;
    .content-slider {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 80px;
       @media (max-width: 992px)  {
            padding: 43px;
      }
       @media (max-width: 600px)  {
              padding: 13px;
    }
      p {
        font-size: 56px;
        color: #fff;
        font-weight: bold;
    right: 10px;
          @media (max-width: 1500px) {
                font-size: 29px;
          }
           @media (max-width: 992px)  {
             font-size: 19px;
      }
      }
      a {
        margin-top: 70px;
        display: inline-flex;
        background: #fff;
        color: #000;
        font-size: 18px;
        font-weight: bold;
        padding: 23px 40px;
        border-radius: 50px;
           @media (max-width: 1500px){
              margin-top: 35px;
              padding: 16px 25px; 
              font-size: 13px;
        }
             @media (max-width: 992px)  {
               margin-top: 15px;
                   padding: 10px 15px; 
              font-size: 11px;
      }
        &:hover{
          opacity: 1;
          background-color: #cede2f;
          color: #000;
        }
      }
    }
  }
}

.Garconita {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 90px 0;
  align-items: flex-start;
      @media (max-width: 1500px) {
        padding: 54px 0;
  }
        @media (max-width: 992px) {
    padding: 19px 0;
  }
  .post-garconita {
    @media (max-width: 1500px) {
             width: 34%;
             .image-post{
                  width: 100%;
                  img{
                        width: 100%;
                  }
             }
        }
         @media (max-width: 700px) {
        width:100%;
  }
    &:first-of-type {
      width: 55%;
        @media (max-width: 1600px) {
    width: 47%;
        }
               @media (max-width: 1500px) {
          width: 57%;
        }
        @media (max-width: 700px) {
        width:100%;
        margin-bottom:30px;
  }
    }
    .title {
      font-size: 35px;
      color: #000;
      font-weight: bold;
      margin-bottom: 0;
            @media (max-width: 1500px) {
            font-size: 25px;
      }
    }
    .box-p {
      p {
        font-size: 25px;
        color: #000;
        margin-top: 30px;
            @media (max-width: 1600px) {
            font-size: 23px;
        }
           @media (max-width: 1500px) {
     font-size: 14px;
     margin-top: 15px;
        }
      }
    }

  }
}
    .box-exp {
      display: flex;
      align-items: center;
      margin-top: 30px;
      span {
        width: 163px;
        height: 163px;
        border: 5px solid #CEDE2F;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 113px;
        font-weight: bold;
        animation: big infinite linear 3300ms;
             @media (max-width: 1500px) {
            font-size: 45px;
                width: 90px;
        height: 90px;
      }
      }
      @keyframes big{
        0%{
              border: 5px solid #CEDE2F;
        }
        50%{
              border: 11px solid #CEDE2F;
        }
        100%{
              border: 5px solid #CEDE2F;
        }
      }
      p {
        display: flex;
        flex-direction: column;
     margin-left: 30px; 
        font-size: 25px;
         @media (max-width: 1500px) {
               font-size: 14px;
      }
        strong {
          font-weight: 500;
        }
      }
    }
.servicii-block {
  background: rgba(247, 247, 247, 0.63);
  padding: 100px 0;
     @media (max-width: 1500px) {
        padding: 54px 0;
  }
   @media (max-width: 992px) {
    padding: 19px 0;
  }

}

.content-portfolio {
  padding: 100px 0;
     @media (max-width: 1500px) {
        padding: 54px 0;
  }
   @media (max-width: 992px) {
    padding: 19px 0;
  }
  .box-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    .item-portfolio {
      height: 529px;
      background-size: cover;
      background-position: left;
      width: 28%;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      transition: 300ms linear all;
      justify-content: flex-end;
      padding: 50px;
      color: #fff;
      position: relative;
      border: 5px solid #fff;
          @media (max-width: 1800px) {
         width: 30%;
         height: 479px;
  }
    @media (max-width: 1500px){
        height: 406px;
  }
     @media (max-width: 1200px){
           width: 49%;
    margin-bottom: 22px;
  }
  @media (max-width: 850px){
        width: 100%;
  }
    @media (max-width: 450px){
        padding: 27px;

  }
      &:hover {
   border: 5px solid rgb(206, 222, 47);
        &:before {
    background: rgba(255, 255, 255, 0.83);
        } 
        .text{
              color: #000;
              span{
                  color: #000;
              }
              a{
                  color: #000;
              }
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        transition: 300ms linear all;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.42);
      }
      .text {
        z-index: 1;
        width: 300px;
        transition: 300ms linear all;
           @media (max-width: 1500px){
              width: 238px;
        }
        span {
          font-size: 35px;
          color: #fff;
          display: inline-flex;
          line-height: 39px;
          transition: 300ms linear all;
                  @media (max-width: 1500px){
                font-size: 28px;
          }
        }
        p {
          font-size: 20px;
          font-weight: 300;
          margin-top: 26px;
          transition: 300ms linear all;
          display: inline-flex;
          margin-bottom: 29px !important;
             @media (max-width: 1500px){
                  font-size: 16px;
                  margin-top: 11px;
                      margin-bottom: 19px !important;
          }
        }
        a {
          color: #fff;
          font-size: 19px;
          letter-spacing: 1px;
          transition: 300ms linear all;
          display: flex;
          align-items: center;
           @media (max-width: 1500px){
                  font-size: 14px;
          }
          i {
            margin-left: 11px;
            font-size: 10px;
          }
        }
      }
    }
  }
}
  .content-servicii {
    .title {
      margin-bottom: 0;
    }
    .post-service-block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item-serivice {
        width: 21%;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
     position: relative;
     cursor: pointer;
        align-items: flex-start;
        &:hover{
          a{
            opacity:1;
          }
        }
      a{
        position: absolute;
    background: #cede2f;
    padding: 10px 30px;
    border-radius: 50px;
    color: #000;
    transition: 300ms linear all;
    font-weight: bold;
    right: 0;
    opacity: 0;
    top: 0;
          @media (max-width: 1700px){
      font-size: 12px;
    padding: 10px 20px;
    }
      }
         @media (max-width: 1500px) {
     
      margin-top: 37px;
  }
   @media (max-width: 1200px) {
        width: 47%;
  }
  @media (max-width: 700px) {
        width: 100%;
          &:nth-child(1n + 2){
          margin-top: 88px;
             }
  }
        &:hover{
   
          opacity:1;
          .icon{
                   background: #e1f331;
            img{
                  transform: scale(1.1);
            }
          }
        }
        .icon {
                    transition: 300ms linear all;
          width: 141px;
          height: 141px;
          background: #CEDE2F;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
             @media (max-width: 1500px){
                width: 90px;
    height: 90px; 
          }
          img {
            width: 42%;
            transition: 300ms linear all;
          }
        }
        span {
          font-size: 25px;
          font-weight: bold;
          margin: 35px 0;
          color: #000;
             @media (max-width: 1500px) {
            font-size: 16px;
    margin: 15px 0;
          }
        }
        p {
          font-size: 19px;
          color: #000;
                 @media (max-width: 1500px) {
            font-size: 14px;
          }
        }
      }
    }
  }
.consultatie {
  padding: 100px 0;
  background: #EFEFEF;
     @media (max-width: 1500px) {
        padding: 54px 0;
  }
   @media (max-width: 992px) {
    padding: 19px 0;
  }
  form {
    display: flex;
    justify-content: space-between;
    align-items: center;
       @media (max-width: 992px)  {
          flex-direction: column;
    align-items: flex-start;
    }
    .back {
      width: 37%;
              @media (max-width: 1500px){
        width: 35%;
      }
       @media (max-width: 992px) {
          width: 100%;
          margin-bottom:20px;
        }
      input {
        width: 100%;
        border: 0;
        background: #E6E6E6;
        padding: 35px 40px;
        font-size: 18px;
        @media (max-width: 1500px) {
          padding: 18px 26px;
    font-size: 14px;
        }
      }
    }
    button {
      padding: 35px 95px;
      background: #CEDE2F;
      border: none;
      font-size: 18px;
      color: #000;
      font-weight: bold;
      @media (max-width: 1500px) {
          padding: 18px 26px;
    font-size: 14px;
        }
    }
  }
}

.contant-noutati {
  .box-portfolio {
    .item-portfolio {
      &:hover {
            border: 5px solid #cede2f;
        &:before {
          background: rgba(255, 255, 255, 0.83);
        }
        .text {
          p {
            color: #000;
          }
          span {
            color: #000;
          }
          a {
            color: #000;
          }
        }
      }
    }
  }
}

footer {
  background: #372F2D;
  padding: 50px 0;
    @media (max-width: 1500px) {
           padding: 30px 0;
        }
            @media (max-width: 992px) {
           padding: 20px 0;
        }
  .content-nav{
      @media (max-width: 550px){
          flex-direction: column;
    align-items: flex-start;
  }
  }
  .info{
           @media (max-width: 1200px){
      margin-right: auto;
    }
     @media (max-width: 650px){
              display: flex !important;
  }
     @media (max-width: 550px){
        margin:30px 0;
  }
  } 
  .link {
         
    a { 
      color: #fff ;
      font-weight: bold;
       
      span {
        font-weight: bold;
        color: #fff !important;
     /*       font-size: 19px !important;
    line-height: 34px !important;*/ 
      }
    }
  }
  .menu {
     @media (max-width: 1200px){
                display: none !important;
      }
    }
    li {
      a {
        color: #fff !important;
        
    }
  }
  .social-link {
    @media (max-width: 1169px){
      display: flex !important;
    } 
    @media (max-width: 650px){
              margin-left: 0;
  }
    &:before {
      height: 171%;
      left: -76px;
      @media (max-width:2000px){
                display: none;
  }
    }
    i { 
      color: #372F2D;
    }
  } 
}

/*page-portfolio*/
 
.page-portfolio{ 
  .tab-list{
    margin-bottom: 76px;
        @media (max-width: 1500px){
              margin-bottom: 49px;
        }

    .tab-activ{
      color: #cede2f;
    }
    span {
    font-size: 20px;
    font-weight: bold;
    margin-right: 83px;
    color: #000;
    @media (max-width: 1500px) {
          font-size: 14px;
      margin-right: 41px;
    }
     @media (max-width: 600px){ 
          margin-right: 16px;
    }
    &:last-of-type{
       margin-right: 0px;
}
  }
}
.content-portfolio{
      padding-bottom: 0;
  .item-portfolio{
    margin-bottom: 103px;
         @media (max-width: 1500px) {
          margin-bottom: 59px;
    }
}
}

}

/*end*/
  
.Single-Portfolio{  
  .slider-stop{ 
   .generalGrid{
    width: 100%;
     .full-sliders{
    position: relative; 
    .owl-carousel{
         .item{
        height: 950px;
    background-size: cover;
    background-position: center top;
        @media (max-width: 1500px){
      height: 692px;
    }
      @media (max-width: 992px) {
          height: 436px;
    }
    .txt-box{
          position: relative;
    left: 339px;
    background: #fff;
    width: 600px; 
    padding: 40px 60px;
          @media (max-width: 1750px) {
          left: 294px;
    }
    @media (max-width: 1650px){  
          left: 241px;
    }  
       @media (max-width: 1500px) {
      width: 473px;
    }
    @media (max-width: 1300px){
          top: 50%;
    left: 50%; 
        background: #ffffffc9;
    transform: translate(-50% , -50%);
    }
     @media (max-width:  650px){
          width: 305px;
              padding: 25px 36px;
    }
    .title-txt{
          font-size: 60px;
    color: #000;
    font-weight: bold;

           @media (max-width: 1500px){
          font-size: 27px;
    margin-bottom: 10px;
    display: inline-flex;

    }
         @media (max-width:  650px){
          font-size: 17px;
    }
  }
  p{
        font-size: 19px;
    color: #8B8B8B;
       @media (max-width: 1500px) {
          font-size: 13px;
    }
    @media (max-width:  650px){
          font-size: 11px;
    }
  }
  .elem{
        display: flex;
    justify-content: space-between;
        margin-top: 30px;
            width: 80%;
              @media (max-width: 1500px){
                  margin-top: 10px;
            }
              @media (max-width:  650px){
           margin-top: 5px;
    }
            .color{
                  color: #029302;
            }
    span{
          font-size: 17px;
    font-weight: bold;
       @media (max-width: 1500px) {
          font-size: 12px;
    }
    }
  }
    }
    }
      .owl-nav{
        position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    display: flex;
    justify-content: space-between;
    button{
          width: 88px;
    height: 88px; 
    background: #000 !important;  
    border-radius: 50%;
    outline: 0;
    img{
          width: 28%;
    }
        @media (max-width: 1500px){
          width: 66px;
    height: 66px;
    }
            @media (max-width:992px){
          width: 29px;
    height: 29px;
    }
    }
    }
    .owl-dots{
      display: none;
    }
 
    }
      
  }
   } 
  }
 
}

/*page single portfolio*/



/*end*/

.page-noutati{
  .content-noutati{
    .box-noutati{
          margin: 100px 0;
          @media (max-width:1500px){
                margin: 54px 0;
        }
        @media (max-width:992px){
                margin: 19px 0;
        }
      .item-noutati{
            display: flex;
    align-items: center;
        margin-bottom: 80px;
    
        &:last-of-type{
          margin-bottom:0;
        }
          @media (max-width:1200px){
              flex-direction: column;
    align-items: flex-start;
        }
             @media (max-width:992px){
              margin-bottom: 28px;

        }
      
    .image-news {
      width: 67%;
      @media (max-width:1200px){
                 width: 100%;
        }
      img{ 
            width: 100%;
      } 
      }
      .text-noutati{ 
        width: 33%;
    height: 100%; 
    display: flex;
    flex-direction: column;
    padding: 55px;
    align-items: flex-start;
            background-size: cover;
    background-position: center;
      @media (max-width:1500px) {
          padding: 33px;
    }
     @media (max-width:1200px){
                 width: 100%;
        }
            @media (max-width:600px) {
              padding: 22px;
        }
    pre{
          font-size: 17px;
    color: #000;
      }
      span{
            font-size: 30px;
    font-weight: bold;
    width: 200px;
    line-height: 35px;
       @media (max-width:1500px){
          font-size: 23px;
    line-height: 30px;
    }
     @media (max-width:600px) {
              line-height: 21px;
                   font-size: 15px;
                   width: 143px;
        }
      }
      p{
            font-size: 17px;
    color: #8B8B8B;
    margin: 30px 0;
    margin-bottom: 40px !important;
       @media (max-width:1500px){
          font-size: 14px;
    margin: 12px 0;
    margin-bottom: 23px !important;
      }
        @media (max-width:600px) {
          margin: 14px 0;
    margin-bottom: 16px !important;
        }
    }

    }
    }
    } 
  }
}
 .all-link{
    padding: 20px 50px;
background: #CEDE2F;
border-radius: 50px;
color: #000;
font-size: 17px;
display: inline-flex;
align-items: center;
    @media (max-width:1500px) {
      padding: 10px 29px;
font-size: 13px;
}
i{
      margin-left: 12px;
font-size: 12px;
 @media (max-width:1500px) {
      font-size: 9px;
}
  }
}

.page-servicii{
  .servicii-block{
    padding-top: 0;
  }
  .title-bar{

    flex-wrap: wrap;
    .full-text{
          width: 100%;
    display: flex;
    margin: 100px 0;
         @media (max-width:1500px){
          margin: 53px 0;
    }
        @media (max-width:1200px){
          margin: 19px 0;
    }
    p{
          font-size: 25px;
    line-height: 33px;
    font-weight: 300;
     @media (max-width:1500px) {
          font-size: 14px;
    line-height: 22px;
    }
  } 
    }
  }
}

.page-serviciiSingle{
  .serviciiSingle-block{
    margin: 100px 0;
      @media (max-width:1500px) {
        margin: 53px 0;
    }
      @media (max-width:992px) {
        margin: 19px 0;
            margin-bottom: 50px;
    }
  }
  .item-seriviceSingle{
    .image{
      img{
            width: 100%;
      }
    } 
    .elemet-cerviceSingle{
          margin-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width:1500px){
          margin-top: 25px;
    }
     @media (max-width:992px){
          flex-direction: column;
    align-items: flex-start;
    } 
    .box-exp{ 
      margin-top: 0; 
    }
    .descriere{
          width: 67%;
               @media (max-width:992px){
                width: 100%;
    margin-top: 20px;
          }
          p{
                font-size: 25px;
                color: #000;
                 @media (max-width:1500px){
                  font-size: 14px;
                }
          }
    }
    }
  } 
}

.page-noutatiSingle{
.title-bar{
    .box-p{ 
      p{
        &:first-of-type{
              font-size: 17px;
            }
      }
    } 
  }
   .content-noutatiSingle{
       margin: 100px 0;
           @media (max-width:1500px) {
        margin: 53px 0;
  
    }
      @media (max-width:992px) {
        margin: 19px 0;
            margin-bottom: 50px;
    }
    .image-news {
      img{
        width: 100%;
      }
     }
     .box-p{
      margin: 80px 0;
        @media (max-width:1500px){
            margin: 50px 0;
      }
       @media (max-width:992px){
            margin: 25px 0;
      margin-top: 21px;
      }
      p { 
    font-size: 19px;
    color: #000;
    margin-bottom: 30px !important;
      @media (max-width:1500px) {
          font-size: 16px;
    }
       @media (max-width:992px){
            font-size: 14px;
      }
    &:last-of-type{
    margin-bottom: 0px !important;
} 
}
     }
    }
    .box-image{
      display: flex;
    justify-content: space-between;
     @media (max-width:992px){
          flex-wrap: wrap;
      }
      .box-imt {
    width: 49%;
       @media (max-width:992px){
           width: 100%;
           margin-bottom: 30px;
           &:last-of-type{
             margin-bottom: 0px;
           }
      }
    img { 
    width: 100%;
}
}
    }

  
}
    .form{
          display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 80px;
     @media (max-width:992px){
                      margin-top: 60px;
                      flex-wrap: wrap;
                 }
   
    form{
          width: 73%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
     @media (max-width:992px){
                   width: 100%;
                 }
    .back {
    width: 30%;
      letter-spacing: 1px;
    margin-bottom: 38px;
    @media (max-width:992px){
          margin-bottom: 22px;
    }
        @media (max-width:700px){
                   width: 100%;
                 }
    &:last-of-type{
        width: 100%;
    }
    input{
      width: 100%;
    padding: 29px 35px;
    background: #F6F6F6;
    border: 1px solid #EAEAEA;
    outline: 0;
    font-size: 17px;
       @media (max-width:1500px) {
      padding: 15px 24px;
    font-size: 14px;
    }
  
    } 
    textarea{
          width: 100%;
    padding: 29px 35px;
    background: #F6F6F6;
    border: 1px solid #EAEAEA;
    outline: 0;
    font-size: 17px;
    letter-spacing: 1px;
    height: 280px;
    min-height: 280px;
    max-width: 100%;
    min-width: 100%;
       @media (max-width:1500px) {
      padding: 15px 24px;
    font-size: 14px;
       height: 180px;
    min-height: 180px;
    } 
    }

}
 button{
      padding: 36px 130px;
    border: none;
    background: #cede2f;
    color: #000;
    font-size: 18px;
    outline: 0;
    font-weight: bold;
    opacity: 1;
    @media (max-width:1500px) {
      padding: 15px 24px;
    font-size: 14px;
    }
    }
    }
    }
 .title-form{
          width: 250px;
           @media (max-width:1500px){
    width: 235px;
           }
 @media (max-width:992px){
                   width: 100%;
                   margin-bottom: 30px;
                 }
          span{
                width: 141px;
    height: 5px;
    background: #CEDE2F;
    display: flex;
    margin-bottom: 20px;
        @media (max-width:1500px) {
        height: 2px;
    width: 61px;
       }
          }
          p{
            font-size: 45px;
    font-weight: bold;
    line-height: 47px;
    @media (max-width:1500px){
font-size: 31px;
    line-height: 37px;
       }
           @media (max-width:992px){
            font-size: 22px;
    line-height: 15px;
          }
       }
    }
.page-contacte{
  .contactes{
     margin: 100px 0;
           @media (max-width:1500px) {
        margin: 53px 0;
  
    }
      @media (max-width:992px) {
        margin: 19px 0;
            margin-bottom: 50px;
    }
    .form{
            @media (max-width:992px) {
            margin-top: 37px;
      }
    }
    .map{
      display: flex;
    height: 600px;
              @media (max-width:1500px){
                height: 500px;
              }
               @media (max-width:992px){
                height: 300px;
              }
    iframe{
          width: 100%;
    height: 100%;
    }
    } 
    .box-info{
          margin: 90px 0;
    display: flex;
    justify-content: space-between;
          @media (max-width:1500px) {
          margin: 52px 0;
    }
      @media (max-width:992px) {
      margin: 19px 0;
        flex-direction: column;
    }
    
    .info-item{
       @media (max-width:992px) {
    margin-bottom: 20px;
    &:last-of-type{
          margin-bottom: 0px;
    }
    }
      .titl {
    font-size: 17px; 
    color: #9F9F9F;
    margin-bottom: 10px;
    display: inline-flex;
    margin-right: 0;
        @media (max-width:1500px){
                   font-size: 14px;
              }
}
.links {
    font-size: 30px;
    font-weight: bold;
    flex-direction: column;
    display: flex;
     @media (max-width:1500px){
                   font-size: 16px;
              }
    a {
    color: #000;
    span {
    margin-right: 76px;
     @media (max-width:1500px){
                   margin-right: 26px;
              }
}
}
}
p{
  font-size: 30px;
    font-weight: bold;
       @media (max-width:1500px){
                   font-size: 16px;
              }
}
    }
    }
  }
} 

.page-about{
  .item-serivice{
a{
  right: -18px !important;
} 
  }
 .about{
  .box-about{
      margin: 100px 0;
           @media (max-width:1500px) {
        margin: 53px 0;
  
    }
      @media (max-width:992px) {
        margin: 19px 0;
            margin-bottom: 50px;
    }
    .item-about {
  
    display: flex;
    justify-content: space-between;
        align-items: flex-start;
        @media (max-width:992px){
              flex-wrap: wrap;
        }
    &:last-of-type{
          padding: 50px 32px;
          margin-top: 100px;
                 @media (max-width:992px){
                margin-top: 37px;
                    padding-bottom: 0;

          }
        }
        .icon{
          img{
            width: 42%;
          }
        }
        .item-serivice{
    margin-top: 0;
    margin-bottom: 50px;
      @media (max-width:600px){
          margin-bottom: 20px;
    }
        }
    .title-bar{
      margin: 0;
    margin-bottom: 64px;
    width: 100%;
    margin-top: 30px;
        @media (max-width:992px){
          margin-bottom: 35px;
  
    margin-top: 19px;
    }
    span{
      background-color: transparent;
    }
    }
    img{
          width: 100%;
    } 
    .text-about {
    width: 80%; 
     @media (max-width:992px){
    width: 100%; 
     }
    .title-text{
          font-size: 35px;
    font-weight: bold;
    margin-bottom: 100px;
    display: flex;
        margin-top: 28px;
         @media (max-width:1500px){
              font-size: 21px;
    margin-bottom: 50px;
        }
             @media (max-width:992px) {
              margin-bottom: 23px;
                  margin-top: 0;
                  font-size: 16px;

        }
    }
    p{
          font-size: 19px;
    margin-bottom: 30px !important;
     @media (max-width:1500px){
          font-size: 14px;
    margin-bottom: 16px !important;
    }
    &:last-of-type{
      margin-bottom: 0px !important;
    }
    }
}
}
  }
 } 
}

.page-team{
  .box-about{   
  .item-about{
    margin-top: 100px;
    &:last-of-type{ 
padding:0 !important;
   @media (max-width:1500px){
      margin-top: 63px !important;
}
    @media (max-width:1500px){
              margin-top: 46px !important;
         }
    }
      @media (max-width:1500px){
         &:first-of-type{
           margin-top: 0;
         }

    }
    .text-about{
      display: flex;
    justify-content: space-between;
    @media (max-width:700px){
                  flex-direction: column;
            }
    .singleTeam{
          width: 37%;
            @media (max-width:1500px){
                  width: 25%;
            }
           @media (max-width:700px) {
                  width: 50%;
    margin-bottom: 19px;
  } 
    }
    .team-boxP{
     width: 58%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
       @media (max-width:1500px) {
          width: 69%;
          justify-content: flex-start;
    }
        @media (max-width:700px) {
          width: 100%;
        } 
    .title-text {
      margin-bottom: 41px !important;
     @media (max-width:1500px)  {
            margin-top: 0;
      }
         @media (max-width:700px) {
            margin-bottom: 14px !important;
      }
}
    }
    }
  }
  }
  .full-team{
    background: #EFEFEF;
    padding: 100px 0;
        padding-bottom: 0;
       @media (max-width:1500px) {
        padding: 53px 0;
      padding-bottom: 0; 
    }

      @media (max-width:992px) {
        padding: 19px 0;
              padding-top: 50px;
     }
    .item-about {
          display: flex;
    justify-content: space-between;
    margin-top: 0;
        @media (max-width:992px){
          flex-wrap: wrap;
    } 
    .text-about {
    width: 80%;
      @media (max-width:1500px) {
             width: 71%;
    }
         @media (max-width:992px){
          width: 100%;
    } 
    .box-image-team {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between;
    .team-image {
    width: 26%;
    margin-bottom: 100px;  
        position: relative;
        cursor: pointer;

        &:hover{
.effectas{
   opacity: 1;
}
        };
        .effectas{
          background: rgba(206,224,2,0.85);
          position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: 400ms linear all;
    width: 100%;
    opacity: 0;
    height: 100%;
        display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 26px; 
    span{
      font-weight: bold;
    }
    p{
      margin: 10px 0 !important;
    }
    .social-link{ 
          margin-left: 0;
    margin-top: 10px;
    
    }
        }
    @media (max-width:1500px) {
          width: 26%;  
    margin-bottom: 50px;
    }
@media (max-width:992px){
      width: 46%; 
}
 
     @media (max-width:500px){
         width: 100%;
    }
   /*    @media (max-width:400px){
         width: 100%;
    }*/
    &:last-of-type{
      display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000000;
    input{
          position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    }
    @media (max-width:500px){
      padding: 100px;
    } 
      span{
     width: 100px; 
    font-size: 36px; 
    font-weight: bold;
    color: #000;
   @media (max-width:1500px)  {
          font-size: 25px;
      }
       @media (max-width:992px){
            font-size: 15px;
    width: 50px;
      }
    }
    }
    img {
    width: 100%;
}
}
}
}
}
  }
  .botton-title{
    .title-bar{
          margin: 100px 0;
    margin-bottom: 100px;
        margin: 100px 0;
           @media (max-width:1500px) {
        margin: 53px 0;
      margin-bottom: 53px;
    }
      @media (max-width:992px) {
        margin: 39px 0;
            margin-bottom:39px;
    }
    }
  }
}
.form-group{
  margin-bottom: 0;
button{
      position: absolute;
    left: -1px;
    bottom: -1px;
    background: rgba(206, 224, 2, 0.85);
    color: #fff;
    padding: 4px 40px;
    font-size: 17px;
    border: 1px solid #000000;
    outline: 0;
    border-bottom: 1px solid #d3e326;
    border-left: 1px solid #d3e326;
}
}
.diferent-effects {
       position: relative;
    left: -100px;
    opacity: 0;
    transition: 1500ms linear all;

}
.diferent-effects-show {
    left: 0px !important;
    opacity: 1 !important;
}
