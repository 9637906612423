// ---Screen sizes--- //
@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 1120px) { @content ; }
  }
  @else if $point == laptop {
    @media (max-width: 1024px) { @content ; }
  }
  @else if $point == big-tablet {
    @media (max-width: 900px) { @content ; }
  }
  @else if $point == tablet {
    @media (max-width: 768px) { @content ; }
  }
  @else if $point == tablet-small {
    @media (max-width: 630px) { @content ; }
  }
  @else if $point == middle-mob-table {
    @media (max-width: 540px) { @content ; }
  }
  @else if $point == big-mobile {
    @media (max-width: 470px)  { @content ; }
  }
  @else if $point == mobile {
    @media (max-width: 414px)  { @content ; }
  }
  @else if $point == sm-mobile {
    @media (max-width: 320px)  { @content ; }
  }
}